/**
 *
 * VirtualView
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Panel, Tab, Tabs } from 'react-bootstrap';
import { parseISO } from 'date-fns';
import { last } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  STRAVA_NONE,
  STRAVA_CONNECTED,
  STRAVA_LOADING,
} from 'containers/User/constants';
import useLocale from 'containers/SettingsProvider/useLocale';
import Container from 'components/KmVreters/Container';
import ShareLink from 'components/ShareLink';
import ConfirmButton from 'components/ConfirmButton';
import LoginAlert from 'components/UserControl/LoginAlert';
import ExplanationModal from 'components/ExplanationModal';
import { request2, useRequest } from 'utils/request';
import { isPast, isFuture } from 'utils/compareDate';
import messages from '../messages';
import TitleLink from '../TitleLink';
import JoinSwitch from './JoinSwitch';
import Participants from './Participants';
import RaceInfo from './RaceInfo';
import TopResults from './TopResults';
import StravaConnect from './StravaConnect';
import StravaStatus from './StravaStatus';
import joined from '../joined';
import explanationText from './explanationText';
import ResultColumns from './ResultColumns';
import CsvLink from './CsvLink';
import useStravaActivity from './useStravaActivity';
import ActivityStats from './ActivityStats';
import StravaLink from './StravaLink';
import formatTime from '../../../utils/formatTime';

const MIN_SPLIT = 3;

const TABS_RESULTS = 1;
const TAB_ANALYSIS = 2;
const TABS_MAP = 3;

const ShareLinkInline = styled(ShareLink)`
  & .btn-link {
    font-weight: bold;
  }
`;

const ConnectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .text-right {
    flex-grow: 1;
  }
`;

const LinksWrapper = styled.div`
  float: right;
  display: flex;
  align-items: baseline;
  margin-right: -0.5rem;
  a,
  button {
    font-size: 1.2rem;
  }
`;

function joinRace(user, race, locale, setRace, setLoading) {
  return status => {
    const reminder = parseISO(race.date);
    setLoading(true);
    request2({
      url: `/virtual/races/${race.hash}/join`,
      method: 'POST',
      user,
      body: { status, locale, reminder },
    })
      .then(entries => setRace({ ...race, entries }))
      .catch(err => {
        setRace({ ...race, error: err.message });
      })
      .finally(() => setLoading(false));
  };
}

function splitEntries(entries) {
  const result = { F: [], M: [] };
  const lastPos = { F: 0, M: 0 };
  entries.forEach(e => {
    const key = e.sex === 'F' ? 'F' : 'M';
    const list = result[key];
    if (!e.pos) {
      list.push(e);
      return;
    }
    const newPos = e.pos === lastPos[key] ? last(list).pos : list.length + 1;
    lastPos[key] = e.pos;
    list.push({ ...e, pos: newPos });
  });
  return result;
}

function showEntries(entries) {
  if (!entries) {
    return;
  }
  const result = [['naam', '   tijd']];
  entries.forEach(e =>
    result.push([`${e.firstname} ${e.lastname}`, formatTime(e.race_time)]),
  );
  const width = result.reduce((max, cur) => Math.max(max, cur[0].length), 0);
  console.log(
    result.map(([name, time]) => `${name.padEnd(width)} ${time}`).join('\r\n'),
  );
}
function VirtualView(props) {
  const { user, hash } = props;
  const locale = useLocale();
  const history = useHistory();
  const [working, setWorking] = useState(false);
  const [race, setRace] = useRequest({ url: `/virtual/races/${hash}`, user });

  const { api, strava = {} } = user;
  const { status } = strava;

  useEffect(
    () => {
      showEntries(race.entries);
    },
    [race.entries],
  );

  const result =
    race.entries &&
    strava &&
    race.entries.find(
      r => r.athlete_id === (window.stravaUser || strava.athlete_id),
    );
  const { map, chart } = useStravaActivity(result);
  const hasActivity = result && result.activity_id;
  const canEdit = api && race && api.userId === race.user_id;
  const pushShare = canEdit && isFuture(race.date);
  const isConnected = api && status === STRAVA_CONNECTED;
  const updateEntry = joinRace(user, race, locale, setRace, setWorking);
  const canDelete =
    canEdit && race.entries.filter(e => e.activity_id).length === 0;

  const renderConnection = () => {
    if (!api) {
      return <LoginAlert />;
    }
    const { scope } = strava;
    if (!status || status === STRAVA_LOADING) {
      return null;
    }
    if (status === STRAVA_NONE) {
      return <StravaConnect message={messages.connectToJoin} />;
    }
    return (
      <React.Fragment>
        {(!scope || !scope.includes('activity:write')) && (
          <StravaConnect message={messages.reconnectStrava} />
        )}
        {scope && (
          <ConnectionWrapper className="alert alert-info">
            <JoinSwitch
              race={race}
              updateEntry={updateEntry}
              working={working}
            />
            <div className="text-right">
              <StravaStatus />
            </div>
          </ConnectionWrapper>
        )}
      </React.Fragment>
    );
  };

  const renderStatus = () => {
    if (canEdit || !race.is_public || !isFuture(race.date)) {
      const { entries } = race;
      const sepLists =
        entries.filter(e => e.sex === 'F' && e.race_time).length >= MIN_SPLIT &&
        entries.filter(e => e.sex !== 'F' && e.race_time).length >= MIN_SPLIT;
      if (sepLists) {
        const lists = splitEntries(entries);
        return (
          <ResultColumns>
            {['F', 'M'].map(sex => (
              <Panel key={sex}>
                <Panel.Heading>
                  <FormattedMessage {...messages[`H${sex}`]} />
                </Panel.Heading>
                <Participants entries={lists[sex]} isOwn={canEdit} />
                <CsvLink
                  data={lists[sex]}
                  filename={`${race.title}-${sex}.csv`}
                >
                  <FormattedMessage {...messages.downloadCSV} />
                </CsvLink>
              </Panel>
            ))}
          </ResultColumns>
        );
      }
      return (
        <React.Fragment>
          <Participants entries={race.entries} showSex isOwn={canEdit} />
          <CsvLink data={race.entries} filename={`${race.title}.csv`}>
            <FormattedMessage {...messages.downloadCSV} />
          </CsvLink>
        </React.Fragment>
      );
    }
    if (isConnected && joined(strava, race.entries)) {
      return (
        <Alert bsStyle="success">
          <p>
            <FormattedHTMLMessage {...messages.showResultsLaterJoined} />
          </p>
          <p>
            <FormattedMessage {...messages.challengeFriends} />
            <ShareLinkInline>
              <FormattedMessage {...messages.shareRace} />
            </ShareLinkInline>
          </p>
        </Alert>
      );
    }
    return (
      status !== STRAVA_NONE && (
        <Alert bsStyle="info">
          <FormattedHTMLMessage {...messages.showResultsLater} />
        </Alert>
      )
    );
  };

  function handleDelete() {
    request2({
      method: 'DELETE',
      url: `/virtual/races/${race.hash}`,
      user,
    }).then(() => {
      history.push('/virtual');
    });
  }

  const showResults = !(race.loading || race.error || race.isTestRun);
  return (
    <Container width={720}>
      <Container.Header>
        <TitleLink to="/virtual" />
        {race.title || <FormattedMessage {...messages.header} />}
        {canEdit && (
          <LinksWrapper>
            {canDelete && (
              <FormattedMessage {...messages.cancelRace}>
                {text => (
                  <ConfirmButton
                    bsStyle="link"
                    className="px-3 py-2"
                    confirmationText={messages.confirmDeleteRace}
                    onClick={handleDelete}
                    title={text}
                  >
                    <FontAwesomeIcon icon="trash-alt" />
                  </ConfirmButton>
                )}
              </FormattedMessage>
            )}
            <FormattedMessage {...messages.duplicate}>
              {text => (
                <Link
                  to={`/virtual/create/${hash}`}
                  className="px-3 py-2"
                  title={text}
                >
                  <FontAwesomeIcon icon="clone" />
                </Link>
              )}
            </FormattedMessage>
          </LinksWrapper>
        )}
      </Container.Header>
      <div>
        <RaceInfo race={race} />
        {pushShare && (
          <Alert bsStyle="success">
            <FormattedHTMLMessage {...messages.promote} />
          </Alert>
        )}
        <ExplanationModal
          storageKey="showVirtualExpl"
          text={explanationText}
          initialValue={!isPast(race.date)}
        />
        {renderConnection()}
        {hasActivity && (
          <StravaLink entry={result} className="float-right mt-3" />
        )}

        {hasActivity ? (
          <Tabs
            defaultActiveKey={TAB_ANALYSIS}
            id="virtual-tabs"
            className="mb-4"
          >
            <Tab
              eventKey={TAB_ANALYSIS}
              title={<FormattedMessage {...messages.analysis} />}
            >
              {hasActivity && <ActivityStats activity={result} />}
              {chart}
            </Tab>
            <Tab
              eventKey={TABS_MAP}
              title={<FormattedMessage {...messages.map} />}
            >
              {map}
            </Tab>
            {showResults && (
              <Tab
                eventKey={TABS_RESULTS}
                title={<FormattedMessage {...messages.raceResults} />}
              >
                {renderStatus()}
                <TopResults {...race} athleteId={strava.athlete_id} />
              </Tab>
            )}
          </Tabs>
        ) : (
          <Alert bsStyle="info">
            <FormattedMessage {...messages.noActivity} />
          </Alert>
        )}
      </div>
    </Container>
  );
}

VirtualView.propTypes = {
  user: PropTypes.object,
  hash: PropTypes.string,
};

export default VirtualView;
